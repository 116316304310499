.container {
	height: 100vh;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding: 30px 0 45px 0;
	position: relative;
	overflow-x: hidden;
	opacity: 0;
	transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0ms;
	.logo-wrapper {
		display: flex;
		justify-content: center;
		&__logo {
			height: 85px;
		}
	}
	.nav {
		padding-left: 60px;
		display: flex;
		align-self: flex-start;
		width: 100%;
		align-items: center;
		position: relative;
		z-index: 300;
		&__separator {
			width: 50px;
			height: 2px;
			background-color: #000;
			margin: 0 25px;
			position: relative;
			top: 2px;
		}
		&__link {
			color: #000;
			font-size: 15px;
			line-height: 18px;
			font-weight: 300;
			&.active {
				font-weight: 700;
			}
			&--contact {
				position: absolute;
				left: 62%;
				font-size: 15px;
				font-weight: 700;
				color: #000;
				width: 415px;
				line-height: 22px;
			}
		}
	}
	.menu-trigger {
		display: none;
	}
	&.scroll {
		max-height: none;
		height: auto;
	}
	.lang-wrap {
		z-index: 99;
		width: 50px;
		height: 50px;
		background-color: #000;
		color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		position: fixed;
		top: 0;
		left: 50px;
		font-size: 20px;
		cursor: pointer;
		transform: translateY(-100%);
		transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1) 0ms;
		&.open {
			transition: all 0.2s cubic-bezier(0.7, 0, 0.3, 1) 0ms;
			transform: translateY(0);
		}
	}
	&.open {
		opacity: 1;
		transition: all 0.4s cubic-bezier(0.7, 0, 0.3, 1) 0ms;
	}
}

@media all and (min-width: 1600px) {
	.container {
		padding: 50px 0 75px 0;
		max-height: 100vh;
		.logo-wrapper {
			display: flex;
			justify-content: center;
			&__logo {
				height: 130px;
			}
		}
		.nav {
			padding-left: 75px;
			&__separator {
				width: 70px;
				height: 3px;
				margin: 0 30px;
				top: 3px;
			}
			&__link {
				color: #000;
				font-size: 24px;
				line-height: 28px;
				&--contact {
					left: 62%;
					font-size: 20px;
					width: 570px;
				}
			}
		}
		.lang-wrap {
			width: 75px;
			height: 75px;
			left: 75px;
			font-size: 32px;
		}
	}
}

@media all and (max-width: 1200px) {
	.container {
		height: auto;
		max-height: none;
		.nav {
			display: none;
		}
		.sidebar-wrap {
			position: fixed;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: flex-end;
			background-color: transparent;
			transform: translateX(100%);
			transition: 1s ease;
			z-index: 301;
			.sidebar {
				display: block;
				height: 100%;
				background-color: #fff;
				width: 100%;
				padding-left: 0;
				.mobile-nav {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%;
					background-color: #fff;
					align-items: center;
					padding-left: 0;
					&__link {
						line-height: 60px;
						font-weight: 400;
						color: #000;
						font-size: 35px;
						line-height: 42px;
						cursor: pointer;
						&.active {
							font-weight: 700;
						}
					}
					&__separator {
						display: block;
						background-color: #000;
						width: 250px;
						height: 3px;
						margin: 40px 0;
						position: relative;
						&--right::after {
							content: "";
							display: block;
							height: 5px;
							width: 6px;
							position: absolute;
							top: -1px;
							background-color: #000;
							border-radius: 40%;
							right: -25px;
						}
						&--left::before {
							content: "";
							display: block;
							height: 5px;
							width: 6px;
							position: absolute;
							top: -1px;
							background-color: #000;
							border-radius: 40%;
							left: -25px;
						}
					}
				}
			}
			&.open {
				transition: 0.2s ease;
				transform: translateX(0);
			}
		}
		.menu-trigger {
			display: block;
			position: fixed;
			top: 75px;
			right: 3px;
			font-size: 17px;
			line-height: 20px;
			letter-spacing: 5px;
			transform: rotate(90deg);
			text-transform: uppercase;
			cursor: pointer;
			z-index: 999;
			opacity: 1;
			&.open {
				opacity: 0;
			}
		}
		.menu-close {
			position: fixed;
			top: 60px;
			right: 25px;
			cursor: pointer;
			z-index: 999;
			transform: translateY(-130px);
			transition: 0.3s ease-out;
			&__icon {
				width: 40px;
				height: 40px;
				position: relative;
				z-index: 9999;
			}
			&.open {
				transform: translateY(0);
				transition: 0.3s ease-out;
			}
		}
		&.home {
			min-height: 100vh;
		}
	}
}

@media all and (max-width: 900px) {
	.container {
		min-height: auto;
		padding-bottom: 70px;
	}
}

@media all and (max-width: 600px) {
	.container {
		height: auto;
		max-height: none;
		min-height: auto;
		padding: 20px 0 50px 0;
		.logo-wrapper {
			&__logo {
				height: 55px;
			}
		}
		.sidebar-wrap {
			.sidebar {
				.mobile-nav {
					&__link {
						color: #000;
						font-size: 20px;
						line-height: 24px;
					}
					&__separator {
						width: 160px;
						height: 2px;
						margin: 30px 0;
						&--right::after {
							height: 4px;
							width: 4px;
							right: -14px;
						}
						&--left::before {
							height: 4px;
							width: 4px;
							left: -14px;
						}
					}
				}
			}
			&.open {
				transition: 0.2s ease;
			}
		}
		.menu-trigger {
			top: 48px;
			font-size: 10px;
			line-height: 12px;
		}
		.menu-close {
			top: 40px;
			right: 15px;
			transform: translateY(-130px);
			&__icon {
				width: 30px;
				height: 30px;
			}
		}
	}
}
