.solutions {
	flex: 1 1 auto;
	padding: 5px 0 70px 0;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	z-index: 200;
	&-top {
		display: flex;
		&__header {
			flex: 0 0 63%;
		}
		&__aside {
			flex: 0 0 37%;
			padding: 155px 0 0 70px;
			&-text {
				margin-bottom: 20px;
				@extend %basic-text;
			}
		}
	}
	&-middle {
		padding-left: 25%;
		margin-top: 40px;
		&__text {
			@extend %basic-text;
			&:not(:last-of-type) {
				margin-bottom: 20px;
			}
			&--bold {
				font-weight: 700;
			}
		}
	}
	&__line {
		margin: 50px 0 50px;
		margin-left: calc(25% + 30px);
	}
	&__anim-text {
		&--mobile {
			@extend %slider-font;
		}
	}
	&-bottom {
		padding-left: 25%;
		&__text {
			@extend %basic-text;
			line-height: 36px;
			margin: 40px 0;
		}
		&__anim-text {
			@extend %slider-font;
			text-align: right;
			padding-right: 50px;
			span {
				font-size: 40px;
			}
		}
	}
}

@media all and (min-width: 1600px) {
	.solutions {
		padding-top: 30px;
		&-top {
			&__header {
				flex: 0 0 63%;
			}
			&__aside {
				flex: 0 0 37%;
				padding: 225px 0 0 117px;
				&-text {
					margin-bottom: 35px;
				}
			}
		}
		&-middle {
			padding-left: 31%;
			margin-top: 50px;
			&__text {
				&:not(:last-of-type) {
					margin-bottom: 30px;
				}
			}
		}
		&__line {
			margin: 60px 0 60px 0;
			margin-left: calc(31% + 40px);
		}
		&-bottom {
			padding-left: 31%;
			&__text {
				line-height: 42px;
				margin: 50px 0;
			}
			&__anim-text {
				padding-right: 75px;
				span {
					font-size: 45px;
				}
			}
		}
	}
}

@media all and (max-width: 1200px) {
	.solutions {
		padding: 15px 0 0 0;
		&-top {
			&__header {
				flex: 0 0 74%;
			}
			&__aside {
				flex: 0 0 29%;
				padding: 210px 0 0 90px;
			}
		}
		&-middle {
			padding-left: 50px;
			margin-top: 25px;
			position: relative;
			&__text {
				&:not(:last-of-type) {
					margin-bottom: 20px;
				}
				&--bold {
					font-weight: 700;
				}
				&.absolute {
					position: absolute;
					left: 50px;
					bottom: calc(100% + 27px);
					margin-bottom: 0;
				}
			}
		}
		&__line {
			margin: 45px 0 45px;
			margin-left: 36%;
		}
		&-bottom {
			padding-left: calc(36% - 30px);
			&__text {
				line-height: 35px;
			}
			&__anim-text {
				margin-left: 0;
				text-align: right;
				padding-right: 50px;
				span {
					font-size: 35px;
				}
			}
		}
	}
}

@media all and (max-width: 900px) {
	.solutions {
		padding: 0;
		&-top {
			flex-direction: column;
			&__header {
				flex: none;
				width: 83%;
			}
			&__aside {
				flex: none;
				width: 115px;
				padding: 0;
				margin: 175px 0 0 50px;
				margin-left: calc(calc(100% - 500px) / 2);
			}
		}
		&-middle {
			width: 500px;
			margin: 35px auto 0 auto;
			padding-left: 0;
			&__text {
				&:not(:last-of-type) {
					margin-bottom: 30px;
				}
				&.absolute {
					bottom: calc(100% + 90px);
					margin-bottom: 0;
					left: 0;
				}
			}
		}
		&__line {
			margin-left: 0;
			width: calc(100% - 80px);
			align-self: flex-end;
		}
		&__anim-text {
			&--mobile {
				padding-left: 50px;
			}
		}
		&-bottom {
			padding-left: 0;
			width: 500px;
			margin: 0 auto;
			&__text {
				margin: 35px 0;
			}
			&__anim-text {
				padding-right: 0;
				span {
					font-size: 30px;
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.solutions {
		&-top {
			&__header {
				width: 81%;
			}
			&__aside {
				width: 90px;
				margin: 95px 0 0 30px;
				margin-left: calc(calc(100% - 310px) / 2);
			}
		}
		&-middle {
			width: 310px;
			// padding-left: 30px;
			margin-top: 25px;
			&__text {
				&:not(:last-of-type) {
					margin-bottom: 17px;
				}
				&.absolute {
					bottom: calc(100% + 40px);
				}
			}
		}
		&__line {
			margin: 33px 0 26px;
			width: calc(100% - 45px);
		}
		&__anim-text {
			&--mobile {
				padding-left: 30px;
			}
		}
		&-bottom {
			width: 310px;
			margin: 0 auto;
			&__text {
				margin: 20px 0 30px 0;
				line-height: 20px;
			}
			&__anim-text {
				span {
					font-size: 22px;
				}
			}
		}
	}
}

@media all and (max-width: 400px) {
	.solutions {
		&-top {
			&__aside {
				margin-left: 30px;
			}
		}
		&-middle {
			width: 100%;
			padding-left: 30px;
			&__text {
				&.absolute {
					padding-left: 30px;
				}
			}
		}
		&__anim-text {
			&--mobile {
				padding-left: 30px;
			}
		}
		&-bottom {
			width: 100%;
			padding-left: 30px;
			&__anim-text {
				width: 100%;
				padding: 0 30px;
				display: flex;
				flex-wrap: wrap;
				justify-content: flex-end;
			}
		}
	}
}
