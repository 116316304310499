.rodo {
	padding-bottom: 50px;
	&-header {
		width: 35%;
	}
	&-content {
		width: 60%;
		margin: 0 auto;
		padding: 70px 0;
		&__subtitle {
			text-transform: uppercase;
			font-size: 20px;
			margin-bottom: 30px;
		}
		&__text {
			@extend %basic-text;
			&:not(:last-child) {
				margin-bottom: 30px;
			}
			span {
				font-weight: 700;
			}
		}
	}
}

@media all and (min-width: 1600px) {
	.rodo {
		padding-top: 30px;
		&-header {
			width: 48%;
		}
		&-content {
			&__subtitle {
				font-size: 36px;
				margin-bottom: 50px;
				text-align-last: center;
			}
			&__text {
				&:not(:last-child) {
					margin-bottom: 40px;
				}
			}
		}
	}
}

@media all and (max-width: 1200px) {
	.rodo {
		&-header {
			width: 40%;
		}
		&-content {
			width: 60%;
			padding: 50px 0;
			&__subtitle {
				font-size: 19px;
				margin-bottom: 30px;
			}
		}
	}
}

@media all and (max-width: 900px) {
	.rodo {
		&-header {
			width: 50%;
		}
		&-content {
			width: 80%;
			&__text {
				font-size: 20px;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.rodo {
		&-content {
			width: 100%;
			padding: 50px 30px;
			&__text {
				font-size: 16px;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.rodo {
		&-content {
			padding: 50px 20px;
			&__text {
				font-size: 14px;
			}
		}
	}
}
