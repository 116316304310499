%reset-input {
	box-shadow: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	outline: none;
	border: none;
	border-radius: 0;
	background-color: #fff;
}

// .contact-form {
// 	display: flex;
// 	flex-direction: column;
// 	margin-top: 5px;
// }

.contact-input,
.contact-textarea {
	@extend %reset-input;
	border-bottom: 2px solid #000;
	padding-bottom: 15px;
	font-size: 15px;
	line-height: 18px;
	font-weight: 200;
	color: #000;
	width: 100%;
	margin-bottom: 15px;
	font-family: "fieldwork-hum", sans-serif;
	&::-webkit-input-placeholder {
		font-family: "fieldwork-hum", sans-serif;
		font-size: 15px;
		line-height: 18px;
		font-weight: 200;
		color: #000;
	}
}
.contact-textarea {
	height: 94px;
	padding-top: 5px;
}
.contact-button {
	padding: 5px 0;
	align-self: flex-end;
	@extend %reset-input;
	font-size: 18px;
	line-height: 18px;
	font-weight: 700;
	color: #000;
	text-transform: uppercase;
	cursor: pointer;
	margin-top: 10px;
	font-family: "fieldwork-hum", sans-serif;
	letter-spacing: 3px;
	background-color: transparent;
}

@media all and (min-width: 1600px) {
	.contact-input,
	.contact-textarea {
		font-size: 20px;
		line-height: 24px;
		margin-bottom: 30px;
		&::-webkit-input-placeholder {
			font-size: 20px;
			line-height: 24px;
		}
	}
	.contact-textarea {
		height: 111px;
	}
	.contact-button {
		font-size: 20px;
		padding: 8px 0;
	}
}

@media all and (max-width: 1200px) {
	.contact-input,
	.contact-textarea {
		padding-bottom: 12px;
		font-size: 14px;
		line-height: 17px;
		margin-bottom: 15px;
		&::-webkit-input-placeholder {
			font-size: 14px;
			line-height: 17px;
		}
	}
	.contact-textarea {
		height: 80px;
	}
	.contact-button {
		font-size: 15px;
		line-height: 18px;
	}
}

@media all and (max-width: 900px) {
	.contact-input,
	.contact-textarea {
		padding-bottom: 15px;
		font-size: 16px;
		line-height: 16px;
		margin-bottom: 30px;
		&::-webkit-input-placeholder {
			font-size: 16px;
			line-height: 19px;
		}
	}
	.contact-textarea {
		height: 115px;
	}
	.contact-button {
		margin-top: 0;
	}
}

@media all and (max-width: 600px) {
	.contact-form {
		margin-top: 20px;
	}

	.contact-input,
	.contact-textarea {
		padding-bottom: 7px;
		font-size: 16px;
		line-height: 16x;
		margin-bottom: 25px;
		&::-webkit-input-placeholder {
			font-size: 14px;
			line-height: 14px;
		}
	}
	.contact-textarea {
		height: 70px;
	}
	.contact-button {
		font-size: 12px;
		padding: 3px 0;
	}
}

@media all and (max-width: 600px) {
	.contact-input,
	.contact-textarea {
		padding-bottom: 4px;
		margin-bottom: 20px;
	}
	.contact-textarea {
		height: 80px;
	}
	.contact-button {
		font-size: 14px;
	}
}
