.about {
	display: flex;
	flex: 1 1 auto;
	padding: 5px 0 30px 0;
	position: relative;
	z-index: 200;
	&-header {
		width: 38%;
	}
	&-content {
		width: 62%;
		padding: 180px 0 0 80px;
		&__text {
			@extend %basic-text;
			&:not(:last-child) {
				margin-bottom: 30px;
			}
			span {
				font-weight: 700;
			}
		}
		&__mobile-text {
			margin-top: 50px;
			@extend %slider-font;
			display: flex;
			flex-direction: column;
			.align-right {
				margin-top: 35px;
				align-self: flex-end;
			}
		}
	}
}

@media all and (min-width: 1600px) {
	.about {
		padding-top: 30px;
		&-header {
			width: 40%;
		}
		&-content {
			width: 60%;
			padding: 227px 0 0 115px;
			&__text {
				&:not(:last-of-type) {
					margin-bottom: 40px;
				}
			}
		}
	}
}

@media all and (max-width: 1200px) {
	.about {
		padding: 15px 0 30px 0;
		&-header {
			width: 48%;
		}
		&-content {
			width: 55%;
			padding: 160px 0 0 60px;
		}
	}
}

@media all and (max-width: 900px) {
	.about {
		padding: 0;
		flex-direction: column;
		&-header {
			width: 69%;
		}
		&-content {
			width: 100%;
			padding: 65px 0 0 0;
			&__text {
				width: 500px;
				margin: 0 auto;
				&:last-of-type {
					margin-bottom: 60px;
				}
			}
			&__mobile-text {
				width: 500px;
				margin: 0 auto;
				align-items: flex-end;
				.align-right {
					margin-top: 37px;
					width: 100%;
					display: flex;
					flex-wrap: wrap;
					justify-content: flex-end;
					span {
						// margin-bottom: 10px;
					}
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.about {
		padding: 0;
		flex-direction: column;
		&-header {
			width: 74%;
		}
		&-content {
			padding: 30px 0 0 0;
			&__text {
				width: 290px;
				&:not(:last-child) {
					margin-bottom: 15px;
				}
				&:last-of-type {
					margin-bottom: 27px;
				}
			}
			&__mobile-text {
				width: 290px;
				margin-top: 5px;
				.align-right {
					margin-top: 16px;
				}
			}
		}
	}
}

@media all and (max-width: 400px) {
	.about {
		&-content {
			&__text {
				width: 100%;
				padding: 0 30px;
			}
			&__mobile-text {
				width: 100%;
				padding: 0 30px;
			}
		}
	}
}
