%slider-font {
	span {
		font-size: 45px;
		line-height: 50px;
		font-weight: 700;
		letter-spacing: 2px;
		text-transform: uppercase;
		color: black;
		-webkit-text-fill-color: #fff;
		-webkit-text-stroke-width: 1px;
		-webkit-text-stroke-color: black;
		&:hover {
			-webkit-text-fill-color: #000;
		}
	}
}

%slider-font-big {
	span {
		font-size: 110px;
		line-height: 132px;
		font-weight: 800;
		text-transform: uppercase;
		letter-spacing: 8px;
		color: black;
		-webkit-text-fill-color: #000;
		-webkit-text-stroke-width: 2px;
		-webkit-text-stroke-color: black;
		&:hover {
			-webkit-text-fill-color: #fff;
		}
	}
}

@media all and (min-width: 1600px) {
	%slider-font {
		span {
			letter-spacing: 3px;
			font-size: 55px;
			line-height: 60px;
		}
	}
	%slider-font-big {
		span {
			font-size: 150px;
			line-height: 165px;
			letter-spacing: 10px;
		}
	}
}

@media all and (max-width: 1200px) {
	%slider-font {
		span {
			font-size: 40px;
			line-height: 52px;
		}
	}
	%slider-font-big {
		span {
			font-size: 98px;
			line-height: 118px;
			letter-spacing: 7px;
		}
	}
}

@media all and (max-width: 900px) {
	%slider-font {
		span {
			font-size: 35px;
			line-height: 45px;
		}
	}

	%slider-font-big {
		span {
			font-size: 70px;
			line-height: 74px;
		}
	}
}

@media all and (max-width: 600px) {
	%slider-font-big {
		span {
			font-size: 50px;
			line-height: 55px;
			letter-spacing: 1px;
		}
	}

	%slider-font {
		span {
			font-size: 30px;
			line-height: 40px;
			letter-spacing: 1px;
		}
	}
}

@media all and (max-width: 400px) {
	%slider-font-big {
		span {
			font-size: 40px;
			line-height: 52px;
		}
	}

	%slider-font {
		span {
			font-size: 25px;
			line-height: 32px;
		}
	}
}
