.init {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	background-color: #000;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 199;
	&-video {
		object-fit: cover;
		width: 100%;
		// position: absolute;
		// top: 50%;
		// transform: translateY(-50%);
		height: 100%;
	}
	&-button {
		position: fixed;
		left: 50%;
		bottom: 30px;
		transform: translateX(-50%);
		color: #fff;
		font-size: 16px;
		cursor: pointer;
		z-index: 300;
	}
	video::-webkit-media-controls {
		display: none !important;
	}
}

@media all and (min-width: 1600px) {
	.init {
		&-button {
			font-size: 18px;
		}
	}
}

@media all and (max-width: 1200px) {
	.init {
		&-button {
			font-size: 14px;
		}
	}
}
