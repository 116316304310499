%line-text {
	color: #fff;
	font-weight: 400;
	font-size: 15px;
	line-height: 18px;
	position: relative;
	height: 100%;
	display: flex;
	align-items: center;
}

.horizontal-line {
	width: 100%;
	background-color: #000;
	&--small {
		height: 5px;
		margin-bottom: 25px;
		position: relative;
	}
	&--big {
		height: 25px;
	}
	&--right {
		&::after {
			content: "";
			display: block;
			width: 9px;
			height: 7px;
			position: absolute;
			left: calc(100% + 20px);
			top: -1px;
			background-color: #000;
			border-radius: 40%;
		}
	}
	&--left {
		&::before {
			content: "";
			display: block;
			width: 9px;
			height: 7px;
			position: absolute;
			right: calc(100% + 20px);
			top: -1px;
			background-color: #000;
			border-radius: 40%;
		}
	}
	&__tags {
		height: 100%;
		display: flex;
		justify-content: flex-end;
		padding: 0 30px;
		align-items: center;
		&-item {
			padding-left: 50px;
			@extend %line-text;
		}
	}
	&__text {
		height: 100%;
		padding-left: 30px;
		display: flex;
		align-items: center;
		@extend %line-text;
	}
}

//slider
.slider {
	overflow: hidden;
	position: relative;
	-webkit-backface-visibility: hidden;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);

	&-horizontal {
		width: 100%;
	}
	&-vertical {
		transform: rotate(90deg);
		position: absolute;
		bottom: -50px;
		right: 50px;
		width: 100vh;
		transform-origin: right top;
	}
	&-about-horizontal {
		position: absolute;
		bottom: 20px;
		left: 0;
	}
	&-track {
		display: flex;

		// -webkit-transform: translate3d(0, 0, 0);

		&--solutions-horizontal {
			animation: scrollSolutionsHorizontal $animation_solutions_horizontal
				linear infinite;
			width: calc(600px * 6);
			.anim-text {
				width: 600px;
				@extend %slider-font;
			}
		}
		&--solutions-horizontal-pl {
			animation: scrollSolutionsHorizontalPl
				$animation_solutions_horizontal_pl linear infinite;
			width: calc(960px * 6);
			.anim-text {
				width: 960px;
				@extend %slider-font;
			}
		}
		&--about-vertical {
			animation: scrollAboutVertical $animation_about_vetical linear
				infinite;
			width: calc(1020px * 2);
			.anim-text {
				width: 1020px;
				@extend %slider-font;
			}
		}
		&--about-vertical-pl {
			animation: scrollAboutVerticalPl $animation_about_vetical_pl linear
				infinite;
			width: calc(1460px * 2);
			.anim-text {
				width: 1460px;
				@extend %slider-font;
			}
		}
		&--about-horizontal {
			animation: scrollAboutHorizontal $animation_about_horizontal linear
				infinite;
			width: calc(940px * 4);
			.anim-text {
				width: 940px;
				@extend %slider-font;
			}
		}
		&--about-horizontal-pl {
			animation: scrollAboutHorizontalPl $animation_about_horizontal_pl
				linear infinite;
			width: calc(1330px * 4);
			.anim-text {
				width: 1330px;
				@extend %slider-font;
			}
		}
		&--about-horizontal-mobile {
			animation: scrollAboutHorizontalMobile
				$animation_about_horizontal_mobile linear infinite;
			width: calc(445px * 4);
			.anim-text {
				width: 445px;
				@extend %slider-font;
			}
		}
		&--about-horizontal-mobile-pl {
			animation: scrollAboutHorizontalMobilePl
				$animation_about_horizontal_mobile_pl linear infinite;
			width: calc(535px * 4);
			.anim-text {
				width: 535px;
				@extend %slider-font;
			}
		}
		.anim-text {
			-webkit-backface-visibility: hidden;
		}

		-webkit-perspective: 2000;
		-webkit-backface-visibility: hidden;
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	}
}

@media all and (min-width: 1600px) {
	%line-text {
		font-size: 20px;
		line-height: 25px;
	}

	.horizontal-line {
		&--small {
			height: 7px;
			margin-bottom: 35px;
		}
		&--big {
			height: 35px;
		}
		&--right {
			&::after {
				width: 12px;
				height: 9px;
				left: calc(100% + 25px);
			}
		}
		&--left {
			&::before {
				width: 12px;
				height: 9px;
				right: calc(100% + 25px);
			}
		}
		&__tags {
			padding: 0 35px;
			&-item {
				padding-left: 75px;
			}
		}
		&__text {
			padding-left: 35px;
		}
	}

	.slider {
		&-vertical {
			bottom: -60px;
			right: 22px;
		}
		&-about-horizontal {
			bottom: 75px;
		}
		&-track {
			&--about-vertical {
				width: calc(1200px * 2);
				.anim-text {
					width: 1200px;
				}
			}
			&--about-vertical-pl {
				width: calc(1750px * 2);
				.anim-text {
					width: 1750px;
				}
			}
			&--solutions-horizontal {
				width: calc(750px * 6);
				.anim-text {
					width: 750px;
				}
			}
			&--solutions-horizontal-pl {
				width: calc(1180px * 6);
				.anim-text {
					width: 1180px;
				}
			}
		}
	}
}

@media all and (max-width: 1200px) {
	%line-text {
		font-size: 14px;
		line-height: 17px;
	}

	.horizontal-line {
		&--small {
			height: 4px;
			margin-bottom: 18px;
		}
		&--big {
			height: 25px;
		}
		&__tags {
			padding: 0 20px;
			&-item {
				padding-left: 40px;
			}
		}
	}

	.slider {
		&-track {
			&--solutions-horizontal {
				width: calc(550px * 6);
				.anim-text {
					width: 550px;
				}
			}
			&--solutions-horizontal-pl {
				width: calc(870px * 6);
				.anim-text {
					width: 870px;
				}
			}
		}
	}
}

@media all and (max-width: 900px) {
	%line-text {
		font-size: 12px;
		line-height: 14px;
	}

	.horizontal-line {
		&--small {
			height: 3px;
			margin-bottom: 20px;
		}
		&--big {
			height: 20px;
		}
		&__text {
			padding-left: 20px;
		}
	}
	.slider {
		&-track {
			&--solutions-horizontal {
				width: calc(490px * 6);
				.anim-text {
					width: 490px;
				}
			}
			&--solutions-horizontal-pl {
				width: calc(770px * 6);
				.anim-text {
					width: 770px;
				}
			}
			&--about-horizontal {
				width: calc(700px * 4);
				.anim-text {
					width: 700px;
				}
			}
		}
	}
}

@media all and (max-width: 600px) {
	.slider {
		&-track {
			&--solutions-horizontal {
				width: calc(380px * 6);
				animation-duration: 9s;
				.anim-text {
					width: 380px;
				}
			}
			&--solutions-horizontal-pl {
				width: calc(620px * 6);
				animation-duration: 12s;
				.anim-text {
					width: 620px;
				}
			}
			&--about-horizontal-mobile {
				width: calc(345px * 4);
				.anim-text {
					width: 345px;
				}
			}
			&--about-horizontal-mobile-pl {
				width: calc(410px * 4);
				animation-duration: 6s;
				.anim-text {
					width: 410px;
				}
			}
		}
	}

	.horizontal-line {
		&__tags {
			&-item {
				padding-left: 25px;
			}
		}
	}
}

@media all and (max-width: 400px) {
	%line-text {
		font-size: 9px;
		line-height: 11px;
	}

	.horizontal-line {
		&--small {
			// height: 2px;
			margin-bottom: 15px;
		}
		&--big {
			height: 15px;
		}
		&--right {
			&::after {
				width: 4px;
				height: 3px;
				left: calc(100% + 10px);
				top: 0;
			}
		}
		&--left {
			&::before {
				width: 4px;
				height: 3px;
				right: calc(100% + 10px);
				top: 0;
			}
		}
		&__tags {
			padding: 0 10px;
			&-item {
				padding-left: 20px;
			}
		}
		&__text {
			padding-left: 10px;
		}
	}

	.slider {
		&-track {
			&--solutions-horizontal {
				width: calc(340px * 6);
				.anim-text {
					width: 340px;
				}
			}
			&--solutions-horizontal-pl {
				width: calc(530px * 6);
				.anim-text {
					width: 530px;
				}
			}
			&--about-horizontal-mobile {
				width: calc(310px * 4);
				.anim-text {
					width: 310px;
				}
			}
			&--about-horizontal-mobile-pl {
				width: calc(350px * 4);
				.anim-text {
					width: 350px;
				}
			}
		}
	}
}
