%basic-text {
	font-weight: 300;
	font-size: 20px;
	line-height: 27px;
}

@media all and (min-width: 1600px) {
	%basic-text {
		font-size: 28px;
		line-height: 38px;
	}
}

@media all and (max-width: 1200px) {
	%basic-text {
		font-size: 19px;
		line-height: 26px;
	}
}

@media all and (max-width: 900px) {
	%basic-text {
		font-size: 24px;
		line-height: 34px;
	}
}

@media all and (max-width: 600px) {
	%basic-text {
		font-size: 16px;
		line-height: 20px;
	}
}

@media all and (max-width: 400px) {
	%basic-text {
		font-size: 14px;
		line-height: 18px;
	}
}
