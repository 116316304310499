.home {
	position: relative;
	flex: 1 1 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	&__title {
		font-size: 55px;
		line-height: 42px;
		margin-bottom: 40px;
		font-family: "paralucent", sans-serif;
	}
	&__subtitle {
		font-size: 30px;
		line-height: 36px;
		font-weight: 300;
	}
}

@media all and (min-width: 1600px) {
	.home {
		&__title {
			font-size: 80px;
			line-height: 63px;
			margin-bottom: 60px;
		}
		&__subtitle {
			font-size: 45px;
			line-height: 53px;
		}
	}
}

@media all and (max-width: 900px) {
	.home {
		&__title {
			font-size: 60px;
			line-height: 78px;
			margin-bottom: 0;
			margin-top: auto;
			max-width: 400px;
			text-align: center;
		}
		&__subtitle {
			font-size: 35px;
			line-height: 42px;
			margin-top: auto;
			padding-bottom: 0;
		}
	}
}

@media all and (max-width: 600px) {
	.home {
		&__title {
			font-size: 35px;
			line-height: 45px;
		}
		&__subtitle {
			font-size: 20px;
			line-height: 24px;
		}
	}
}
