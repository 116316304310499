$animation_solutions_horizontal: 6s;
$animation_solutions_horizontal_pl: 10s;
$animation_about_vetical: 5s;
$animation_about_vetical_pl: 7s;
$animation_about_horizontal: 8s;
$animation_about_horizontal_pl: 11s;
$animation_about_horizontal_mobile: 5s;
$animation_about_horizontal_mobile_pl: 5s;

// solutions
@keyframes scrollSolutionsHorizontal {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-600px * 3));
	}
}

@keyframes scrollSolutionsHorizontalPl {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-960px * 3));
	}
}

// about
@keyframes scrollAboutVertical {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-1020px));
	}
}
@keyframes scrollAboutVerticalPl {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-1460px));
	}
}

@keyframes scrollAboutHorizontal {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-940px * 2));
	}
}

@keyframes scrollAboutHorizontalPl {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-1330px * 2));
	}
}

@keyframes scrollAboutHorizontalMobile {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-445px * 2));
	}
}

@keyframes scrollAboutHorizontalMobilePl {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(calc(-535px * 2));
	}
}

@media all and (min-width: 1600px) {
	@keyframes scrollAboutVertical {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-1200px));
		}
	}
	@keyframes scrollAboutVerticalPl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-1750px));
		}
	}
	@keyframes scrollSolutionsHorizontal {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-750px * 3));
		}
	}

	@keyframes scrollSolutionsHorizontalPl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-1180px * 3));
		}
	}
}

@media all and (max-width: 1200px) {
	$animation_solutions_horizontal: 6s;
	$animation_about_vetical: 2s;
	$animation_about_horizontal: 3s;

	// solutions
	@keyframes scrollSolutionsHorizontal {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-550px * 3));
		}
	}
	@keyframes scrollSolutionsHorizontalPl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-870px * 3));
		}
	}
}

@media all and (max-width: 900px) {
	$animation_solutions_horizontal: 6s;
	$animation_about_vetical: 2s;
	$animation_about_horizontal: 3s;

	// solutions
	@keyframes scrollSolutionsHorizontal {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-490px * 3));
		}
	}
	@keyframes scrollSolutionsHorizontalPl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-770px * 3));
		}
	}

	@keyframes scrollAboutHorizontal {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-700px * 2));
		}
	}
}

@media all and (max-width: 600px) {
	$animation_solutions_horizontal: 6s;
	$animation_about_horizontal_mobile: 12s;

	// solutions
	@keyframes scrollSolutionsHorizontal {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-380px * 3));
		}
	}
	@keyframes scrollSolutionsHorizontalPl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-620px * 3));
		}
	}

	//about
	@keyframes scrollAboutHorizontalMobile {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-345px * 2));
		}
	}

	@keyframes scrollAboutHorizontalMobilePl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-410px * 2));
		}
	}
}

@media all and (max-width: 400px) {
	$animation_solutions_horizontal: 6s;
	$animation_about_vetical: 2s;
	$animation_about_horizontal_mobile: 10s;

	// solutions
	@keyframes scrollSolutionsHorizontal {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-340px * 3));
		}
	}
	@keyframes scrollSolutionsHorizontalPl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-530px * 3));
		}
	}

	//about
	@keyframes scrollAboutHorizontalMobile {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-310px * 2));
		}
	}

	@keyframes scrollAboutHorizontalMobilePl {
		0% {
			transform: translateX(0);
		}
		100% {
			transform: translateX(calc(-350px * 2));
		}
	}
}

@keyframes glitch-anim-1 {
	0% {
		clip: rect(93px, 9999px, 55px, 0);
	}

	10% {
		clip: rect(36px, 9999px, 25px, 0);
	}

	20% {
		clip: rect(84px, 9999px, 42px, 0);
	}

	30% {
		clip: rect(94px, 9999px, 2px, 0);
	}

	40% {
		clip: rect(76px, 9999px, 46px, 0);
	}

	50% {
		clip: rect(13px, 9999px, 19px, 0);
	}

	60% {
		clip: rect(18px, 9999px, 22px, 0);
	}

	70% {
		clip: rect(20px, 9999px, 39px, 0);
	}

	80% {
		clip: rect(83px, 9999px, 3px, 0);
	}

	90% {
		clip: rect(83px, 9999px, 42px, 0);
	}

	100% {
		clip: rect(49px, 9999px, 42px, 0);
	}
}

@keyframes glitch-anim-2 {
	0% {
		clip: rect(16px, 9999px, 90px, 0);
	}

	10% {
		clip: rect(67px, 9999px, 92px, 0);
	}

	20% {
		clip: rect(23px, 9999px, 9px, 0);
	}

	30% {
		clip: rect(92px, 9999px, 56px, 0);
	}

	40% {
		clip: rect(67px, 9999px, 28px, 0);
	}

	50% {
		clip: rect(86px, 9999px, 4px, 0);
	}

	60% {
		clip: rect(23px, 9999px, 25px, 0);
	}

	70% {
		clip: rect(96px, 9999px, 66px, 0);
	}

	80% {
		clip: rect(80px, 9999px, 56px, 0);
	}

	90% {
		clip: rect(90px, 9999px, 94px, 0);
	}

	100% {
		clip: rect(28px, 9999px, 68px, 0);
	}
}

[data-text] {
	position: relative;
}

[data-text]:hover:after,
[data-text]:hover:before {
	content: attr(data-text);
	position: absolute;
	color: #000;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	clip: rect(10px, 10px, 10px, 10px);
	background-color: #fff;
}

[data-text]:hover:after {
	transform: translateX(-2px);
	animation: glitch-anim-1 0.7s infinite linear alternate-reverse;
}

[data-text]:hover:before {
	transform: translateX(2px);
	animation: glitch-anim-2 1s infinite linear alternate-reverse;
}
