.header {
	&__title {
		text-align: right;
		padding-left: 50px;
		@extend %slider-font-big;
		position: relative;
		left: 9px;
	}
}

@media all and (min-width: 1600px) {
	.header {
		&__title {
			// margin-bottom: 15px;
			left: 6px;
		}
	}
}

@media all and (max-width: 1200px) {
	.header {
		&__title {
			// left: 9px;
		}
	}
}

@media all and (max-width: 900px) {
	.header {
		&__title {
			left: 6px;
			margin-bottom: 10px;
			padding-left: 0;
		}
	}
}

@media all and (max-width: 600px) {
	.header {
		margin-top: -6px;
		&__title {
			margin-bottom: 0;
			left: 2px;
			padding-left: 20px;
		}
	}
}
