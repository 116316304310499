.contact {
	flex: 1 1 auto;
	padding: 5px 0 30px 0;
	display: flex;
	flex-direction: column;
	justify-content: center;
	position: relative;
	&-header {
		width: 62%;
	}
	&-content {
		display: flex;
		padding: 0 100px 0 60px;
		flex: 1 1 auto;
		align-items: center;
		justify-content: center;
		&__info {
			// align-self: flex-end;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			// padding-bottom: 18px;
			height: 100px;
			// height: 180px;
			margin-top: 45px;
			.info-icons {
				display: flex;
				a {
					img {
						width: 30px;
						margin-right: 30px;
					}
				}
			}
		}
		&__form {
			.contact-form {
				display: flex;
				&__inputs {
					width: 350px;
					margin-right: 50px;
				}
				&__message {
					width: 350px;
					margin-right: 50px;
					position: relative;
					.contact-button {
						position: absolute;
						bottom: -12px;
						right: 0;
					}
				}
				&__info {
					position: absolute;
					bottom: 22px;
					left: 0;
					width: 100%;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					background-color: #000;
					color: #fff;
					padding: 30px 0;
					.title {
						text-transform: uppercase;
						font-size: 20px;
					}
					.message {
						text-align: center;
						font-size: 14px;
						font-weight: 300;
						&--validation {
							text-align: center;
							.sign {
								display: inline-block;
								margin-right: 7px;
								position: relative;
								top: 1px;
								font-weight: 700;
							}
						}
					}
					.close {
						position: absolute;
						top: 5px;
						right: 5px;
						cursor: pointer;
						img {
							width: 20px;
							height: 20px;
						}
					}
				}
			}
		}
		&__title {
			font-size: 20px;
			line-height: 35px;
			font-weight: 700;
			text-transform: uppercase;
			letter-spacing: 1px;
			margin-bottom: 30px;
		}
		&__text {
			@extend %basic-text;
		}
		&__link {
			font-size: 15px;
			font-weight: 700;
			color: #000;
		}
	}
}

.data-wrap {
	margin-bottom: 30px;
}

@media all and (min-width: 1600px) {
	.contact {
		padding-top: 30px;
		&-content {
			padding: 0 100px 0 75px;
			justify-content: center;
			&__info {
				height: 117px;
				// padding-bottom: 20px;
				.info-icons {
					a {
						img {
							width: 40px;
							margin-right: 40px;
						}
					}
				}
			}
			&__form {
				.contact-form {
					&__inputs {
						width: 400px;
						margin-right: 70px;
					}
					&__message {
						width: 400px;
						margin-right: 70px;
					}
					&__info {
						position: absolute;
						bottom: 50px;
						left: 0;
						width: 100%;
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						background-color: #000;
						color: #fff;
						padding: 30px 0;
						.title {
							font-size: 28px;
						}
						.message {
							font-size: 18px;
							&--validation {
								.sign {
									margin-right: 7px;
									top: 1px;
								}
							}
						}
						.close {
							top: 10px;
							right: 10px;
							img {
								width: 30px;
								height: 30px;
							}
						}
					}
				}
			}
			&__title {
				font-size: 28px;
				line-height: 42px;
				letter-spacing: 1px;
				margin-bottom: 40px;
			}
			&__link {
				font-size: 20px;
			}
		}
	}
}

@media all and (max-width: 1400px) {
	.contact {
		&-content {
			padding: 0 100px 0 60px;
		}
	}
}

@media all and (max-width: 1200px) {
	.contact {
		padding: 15px 0 30px 0;
		&-header {
			width: 74%;
		}
		&-content {
			justify-content: center;
			align-items: flex-start;
			padding-top: 40px;
			&__info {
				height: auto;
				padding-top: 10px;
				.data-wrap {
					margin-bottom: 15px;
				}
			}
			&__form {
			}
			&__title {
				font-size: 19px;
				line-height: 35px;
				margin-bottom: 20px;
			}
			&__link {
				font-size: 16px;
			}
		}
	}
}

@media all and (max-width: 900px) {
	.contact {
		padding: 0;
		&-header {
			width: 83%;
		}
		&-content {
			flex-direction: column;
			width: 400px;
			margin: 60px auto 0 auto;
			padding: 0;
			&__info {
				flex: none;
				padding: 0;
				margin-bottom: 30px;
				margin-left: 0;
				justify-content: flex-start;
				.data-wrap {
					margin-bottom: 0;
				}
			}
			&__form {
				flex: none;
				width: 100%;
				max-width: 400px;
				margin: 0 0 50px;
				.contact-form {
					&__inputs {
						width: 100%;
					}
					&__message {
						width: 100%;
					}
				}
			}
			&__title {
				font-size: 24px;
				line-height: 34px;
				margin-bottom: 20px;
			}
			&__text {
			}
			&__link {
				margin-top: 20px;
				font-size: 19px;
			}
		}
	}
}

@media all and (max-width: 600px) {
	.contact {
		&-header {
			width: 78%;
		}
		&-content {
			margin-top: 50px;
			width: 310px;
			&__info {
				margin-bottom: 25px;
			}
			&__form {
				max-width: 100%;
			}
			&__title {
				font-size: 16px;
				line-height: 20px;
				width: 200px;
				margin-bottom: 20px;
			}
			&__text {
				line-height: 20px;
			}
			&__link {
				font-size: 13px;
			}
		}
	}
}

@media all and (max-width: 400px) {
	.contact {
		&-header {
			width: 81%;
		}
		&-content {
			padding: 0 30px;
			width: 100%;
		}
	}
}
