body,
html {
	margin: 0;
	padding: 0;
	font-family: "fieldwork-hum", sans-serif;
	color: #000;
	font-weight: 400;
	font-size: 16px;
}

a {
	text-decoration: none !important;
}

.main-wrapper {
	position: relative;
	z-index: 200;
}

*,
*:after,
*:before {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	outline: none;
}
