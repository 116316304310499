@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

$chars: 5;
$duration: 0.2s;
$bounce: cubic-bezier(0.4, 1.4, 0.75, 0.9);
$scale: 5000;

@keyframes stretch {
	5% {
		transform: scaleX($scale);
		opacity: 0.1;
	}
	15% {
		transform: scaleX(1);
		opacity: 1;
	}
}

.anim-wrap {
	position: fixed;
	width: 100%;
	height: 100vh;
	background-color: transparent;
	top: 0;
	left: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 299;
	.page-anim {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #000;
		z-index: 300;
		&.anim {
			animation: fadeOut 1s;
		}
	}
	h1 {
		// font: 300 100px/1 courier;
		white-space: nowrap;
		color: #fff;
		z-index: 301;
		font-family: "fieldwork-hum", sans-serif;
		font-weight: 800;
		font-size: 100px;
		span {
			display: inline-block;
			animation: stretch $chars * $duration $bounce 0.5s;
			transform-origin: center;

			@for $i from 1 through $chars {
				&:nth-of-type(#{$i}) {
					animation-delay: $i * $duration;
				}
			}
		}
		&.none {
			color: transparent;
		}
	}
}
